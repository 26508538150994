import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../components/seo'
import Title from '../components/title'
import Header from '../components/header'
import Container from '../components/container'
import FeatureBlock from '../components/feature-block'
import Layout from '../layout'
import Image from '../components/image'
import Cta from '../components/cta'
import AnimatedArticle from '../components/animated-article'
import AnimatedAside from '../components/animated-aside'
import P from '../components/paragraph'

const AboutPage = () => {
  const { aboutHero, aboutBottle, orange } = useStaticQuery(graphql`
    query {
      aboutHero: file(relativePath: { eq: "about/about-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 740, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutBottle: file(relativePath: { eq: "about/bottle.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      orange: file(relativePath: { eq: "brands/nod/bottle-nod.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title='About'
        description={`
          Valorem Distribution has a truly global perspective, with established
          perfume distribution pathways around the globe and a specialised local
          knowledge that our clients can call upon at any time.
        `}
      />

      <Header />

      <Container wide noPadding>
        <FeatureBlock>
          <AnimatedArticle onLoad css={`
            @media(min-width: ${({ theme }) => theme.breakpoint.large}) {
              margin-top: 50px;
            }`}
          >
            <Title fancy>About</Title>
            <P>
              There are delivery services, and there&apos;s Valorem.  Out goes
              conventional, in comes Valorem’s distinctively individual
              approach, totally in tune with today&apos;s ambitious niche
              brands. Where a brand strives to be cutting edge, we offer sharp
              thinking. Where a brand dares to be different, we arrive at daring
              solutions.
            </P>
            <P>
              Valorem distribution Limited has a truly global perspective, with
              established distribution pathways around the globe and a
              specialised local knowledge that our clients can call upon at
              anytime. We are currently making waves as far afield as Russia,
              China, the Gulf, America, Europe and the UK, developing
              initiatives with beauty influencers to change perceptions and
              drive sales.
            </P>
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              fluid={aboutHero.childImageSharp.fluid}
              notch='bottomLeft'
              alt='About Valorem Distribution – Third Party Shipping'
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>

      <Container wide>
        <FeatureBlock center grey={false}>
          <AnimatedAside right>
            <Image
              fluid={aboutBottle.childImageSharp.fluid}
              notch='topRight'
              alt='VDL TPS Bottle'
            />
          </AnimatedAside>
          <AnimatedArticle>
            <Title fancy>Brand Values</Title>
            <P>
              We all know how much work goes into creating a luxury product with
              genuinely niche appeal, yet without a distribution service as
              finely tuned as the product itself, opportunities can readily
              evaporate. With many distribution services, their understanding of
              the luxury beauty market is finite, but with Valorem you share the
              benefits of our established links with elite manufacturers and
              retailers, and our passion for working with industry leading
              creatives.
            </P>
            <P>
              Valorem is a celebration of beauty with out boundaries. We take
              pride in being positively original in our thinking and creative,
              bespoke solutions. A distribution solution as audacious as your
              brand.
            </P>
          </AnimatedArticle>
        </FeatureBlock>
      </Container>

      <Container wide noPadding='top'>
        <FeatureBlock center grey={false}>
          <AnimatedArticle>
            <Title fancy>Services</Title>
            <P>
              Valorem Distribution Limited offers a variety of services to
              brands that dare to be different.
            </P>
            <ul css='margin-bottom: 0;' className='list'>
              <li>
                A 360 perspective on a brand idea; ranging from creation,
                manufacturing, marketing and launch into market
              </li>
              <li>
                A distribution agreement, for all / various territories / as
                required – including consultation and strategy for the further
                growth and development of the business
              </li>
              <li>
                3PL – Third Party Logistics – picking, packing and shipping by
                our dedicated inhouse team in the VDL warehouse.
              </li>
            </ul>
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              fluid={orange.childImageSharp.fluid}
              notch='topLeft'
              alt='VDL TPS Bottle'
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>

      <Container width={1100} noPadding='top'>
        <Cta
          text='Welcome to the distribution solution as audacious as your brand'
          buttonText='Get in touch'
          to='/contact'
        />
      </Container>

    </Layout>
  )
}

export default AboutPage
